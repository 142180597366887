/* FONT */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap");
/* COLORS */
/* BREAKPOINTS */
/* BODY */
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: 'Poppins', sans-serif;
  color: #000; }

a {
  color: inherit;
  text-decoration: none; }

a:focus,
input:focus,
button:focus,
*:focus {
  outline: none; }

.content {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 1em; }

@media (max-width: 600px) {
  h1 {
    font-size: 1.5em; } }

@media (max-width: 450px) {
  .content {
    padding-left: 0.5em;
    padding-right: 0.5em; } }

i.fa {
  margin-right: 0.2em; }

/* FLEXBOX */
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

.flex-col {
  display: flex;
  flex-direction: column; }

.flex-centered {
  justify-content: center;
  align-items: center; }

.flex-end {
  justify-content: flex-end; }

/* HEADER BAR */
.header-bar {
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #c90000;
  color: #fff; }
  .header-bar .content {
    justify-content: space-between; }
  .header-bar p,
  .header-bar a {
    color: #fff;
    margin: 0.7em 0 0.5em;
    text-decoration: none; }
    .header-bar p .fa,
    .header-bar a .fa {
      margin-right: 0.4em; }
  .header-bar i.fa {
    margin-left: 0.2em; }
  .header-bar .left {
    flex-basis: 260px; }
  .header-bar .right {
    flex-basis: 370px;
    justify-content: space-between; }
    .header-bar .right a {
      margin-left: 1em; }

@media (max-width: 900px) {
  .header-bar .left {
    display: none; }
  .header-bar .right {
    flex-basis: 100%;
    justify-content: flex-end; } }

@media (max-width: 600px) {
  .header-bar .fa {
    margin-right: 0.2em !important;
    margin-left: 0.1em !important; } }

/* HEADER BANNER */
.header-banner {
  color: #fff;
  height: 60vh;
  min-height: 40em;
  background-image: url("../assets/cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.2); }
  .header-banner .header-overlay {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center; }
  .header-banner .content {
    width: 100%;
    margin-top: 2em;
    font-weight: 500;
    -webkit-filter: drop-shadow(0 0 20px #000);
    filter: drop-shadow(0 0 20px #000); }
    .header-banner .content h1 {
      font-size: 2.5em; }
    .header-banner .content .button {
      background-color: #c90000;
      color: #fff;
      cursor: pointer;
      border: none;
      border-radius: 1em;
      width: fit-content;
      transition: 0.3s;
      padding: 0.5em 2em;
      font-size: 1.2em; }
      .header-banner .content .button:hover {
        background-color: #fff;
        color: #c90000; }

@media (max-width: 600px) {
  .header-banner {
    text-align: center; }
    .header-banner h1 {
      font-size: 2em !important;
      align-self: center; }
    .header-banner .button {
      font-size: 1em !important;
      align-self: center; }
    .header-banner p {
      font-size: 1em !important;
      align-self: center; } }

/* CONTACT */
.contact {
  margin: 5em auto;
  position: relative; }
  .contact .rectangle {
    justify-content: center;
    flex-wrap: nowrap; }
    .contact .rectangle-wrapper {
      background-color: #fff;
      box-shadow: 0em 0em 1em #808080;
      border-radius: 2em;
      margin: 2em;
      padding: 3em 1em 1em;
      width: 250px;
      height: 18em; }
      .contact .rectangle-wrapper .rectangle-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%; }
        .contact .rectangle-wrapper .rectangle-text i {
          color: #c90000;
          font-size: 3em;
          margin: 0; }
        .contact .rectangle-wrapper .rectangle-text p {
          margin: 0.3em; }
  .contact-bgline {
    z-index: -1;
    position: absolute;
    top: 8em;
    width: 100%;
    height: 1em;
    background-color: #c90000; }

@media (max-width: 900px) {
  .contact .rectangle {
    flex-wrap: wrap; }
  .contact-bgline {
    display: none; } }

/* EMERGENCY PROCESS */
.emergency {
  margin: 5em auto;
  text-align: center; }
  .emergency-process {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em; }
    .emergency-process .step {
      width: 100%;
      max-width: 900px;
      position: relative;
      background-color: #c90000;
      color: #fff;
      padding: 0.5em 1em 1em;
      border-radius: 2em;
      margin-bottom: 2em; }
      .emergency-process .step.next ::after {
        content: ' ';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -20px;
        border: solid 20px;
        border-color: #c90000 transparent transparent transparent; }

/* FAQ */
.faq {
  margin: 5em auto;
  text-align: center; }
  .faq-questions {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em; }
    .faq-questions .question {
      width: 100%;
      max-width: 900px;
      box-shadow: 0em 0em 1em #808080;
      border-radius: 2em;
      margin: 1em;
      padding: 1em; }
      .faq-questions .question-text {
        cursor: pointer;
        font-weight: 600;
        padding: 0.5em 1em; }
        .faq-questions .question-text i {
          color: #c90000;
          margin-right: 0.5em; }
      .faq-questions .question-answer {
        height: 0;
        overflow: hidden;
        transition: 0.3s; }
        .faq-questions .question-answer.opened {
          padding: 0.5em 1em;
          height: auto; }

/* FOOTER */
.footer {
  background-color: #c90000;
  color: #fff; }
  .footer .flex-row {
    justify-content: center;
    align-items: center; }
  .footer .flex-col {
    margin: 1em;
    align-items: center;
    text-align: center;
    padding-top: 1em; }
    .footer .flex-col p,
    .footer .flex-col a {
      margin: 0.4em; }
    .footer .flex-col a {
      text-decoration: underline; }
    .footer .flex-col .fa {
      cursor: pointer;
      font-size: 1.8em;
      margin: 0 0.2em; }

/* COOKIES */
.cookies-bar {
  z-index: 100;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #c90000;
  color: #fff; }
  .cookies-bar .content {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center; }
  .cookies-bar-text a {
    text-decoration: underline; }
  .cookies-bar-button {
    background-color: #fff;
    color: #c90000;
    cursor: pointer;
    border: none;
    border-radius: 1em;
    margin: 0.5em;
    padding: 0.5em 2em; }
    .cookies-bar-button.negative {
      background-color: #c90000;
      color: #fff;
      border: solid 1px; }

@media (max-width: 900px) {
  .cookies-bar .content {
    flex-wrap: wrap;
    justify-content: end;
    text-align: center;
    margin-bottom: 1em; } }
